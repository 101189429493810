h2 .glyphicon {
    font-size: 25px;
}

ul {
	list-style-type: none;
}

.google-maps {
    position: relative;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.lead {
	font-size: 1.25em
}